// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}/${sublink}`;
}

const ROOT = "/";
const ROOTS_AUTH = '/auth';
const ROOTS_PRODUCT = '/product';
const ROOTS_PRODUCTS = '/products';


// ----------------------------------------------------------------------

const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: "/login",
  register: "/register",
  logout: "/logout"
};

const PATH_STORE = {
  storeDetails: "/store-details" ,
  searchStore: "/search-store" 

  
};


const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

const PATH_PRODUCT = {
  root: ROOTS_PRODUCTS,

};



export const pathConfigs = {
  path, ROOT, ROOTS_AUTH, ROOTS_PRODUCT, ROOTS_PRODUCTS, PATH_AUTH, PATH_PAGE,
  PATH_PRODUCT, PATH_STORE
}

