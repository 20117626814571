// @mui
import { Box, IconButton, Typography } from '@mui/material';
import { Markup } from 'react-render-markup';
import { useEffect, useState } from 'react';
import Iconify from '../../components/Iconify';
import { APP_CONFIGS } from '../../config';
import axiosInstance from '../../utils/axios';
import useNotification from '../common/useNotification';
import useResponsive from '../../hooks/useResponsive';
import CommonConstants from '../constants/CommonConstants';


export default function SiteAnnouncement(props) {
    const [announcements, setAnnouncements] = useState([]);
    const [msg, sendNotification] = useNotification();
    const [announcementMsg, setAnnouncementMsg] = useState("");
    let indexPosition = 1;
    const isMobile = useResponsive('only', 'xs');

    useEffect(() => {
        getSiteAnnouncements();
    }, []);

    useEffect(() => {
        //  clearInterval(interval);
        setInterval(() => {
            if (announcements && announcements.length > 1) {
                setAnnouncementMsg(announcements[indexPosition].announcement);
                if (indexPosition < (announcements.length - 1)) {
                    ++indexPosition;
                } else {
                    indexPosition = 0;
                }
            }
        }, CommonConstants.SITE_ANNOUNCEMENT_TIME_INTERVAL);

    }, [announcements.length > 0]);



    const getSiteAnnouncements = async () => {
        let data = { offset: 0, limit: 0 };
        try {
            const response = await axiosInstance.get(APP_CONFIGS.SITE_ANNOUNCEMENTS_PATH, { params: data, headers: { "accept": "application/json" } });

            if (response.data && response.data.data && response.data.data.length > 0) {
                setAnnouncements(response.data.data);
                setAnnouncementMsg(response.data.data[0].announcement);
            }

        } catch (error) {
            console.error(`getSiteAnnouncements: ${error.data ? error.data : error}`);
            if (error.data) {
                sendNotification({ msg: (error.data.detail ? error.data.detail : error.data), variant: "error" })
            }
        }


    }

    return (
        <>
            {announcements.length > 0 &&
                <Box sx={{ alignItems: 'center', backgroundColor: "#F6B327" }}>
                    <Box sx={{ minHeight: (isMobile ? "30px" : "65px"), padding: (isMobile ? 1 : "18px"), textAlign: 'center', fontSize: isMobile ? 12 : 15 }} className="site-announcement">
                        <Typography variant="body" className="black-text-color" gutterBottom component="div" sx={{ fontWeight: "normal", fontFamily: "Poppins", m:0 }}>
                            <Markup markup={announcementMsg} />
                        </Typography>
                    </Box>

                </Box>
            }
        </>
    );
}


