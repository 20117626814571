import CommonConstants from './../portal/constants/CommonConstants';

export const WEEK_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

// This will be used for product side products(i.e. it does not has bases but has sizes).
export const DEFAULT_BASE = {id: "0", name: "DEFAULT_BASE"};

export function handleRefresh() {
    window.location.reload();
};

export function test() {
    window.location.reload();
};

export function isNumeric(num) {
    if (!num) {
        return false;
    }
    return !isNaN(num)
}

export function getDay(index) {
    if (index && isNumeric(index)) {
        return WEEK_DAYS[index - 1];
    }
    return index;
};

export function isValidEmail(email) {
    let isValidEmail = false;
    // if (email.length > 0 && (/^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email))) {
    //     isValidEmail = true;
    // }
    if (email.length > 0 && (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))) {
        isValidEmail = true;
    }
    return isValidEmail;
};

export function isValidPhoneNumber(phoneNumber) {
    let isValidPhoneNumber = false;
    if (phoneNumber.length > 0 && (/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(phoneNumber))) {
        isValidPhoneNumber = true;
    }
    return isValidPhoneNumber;
};

export function isValidAccountNumber(AccountNumber) {
    let isValidAccountNumber = false;
    if (/^(?:[0-9]{11}|[0-9]{2}-[0-9]{3}-[0-9]{6})$/.test(AccountNumber)) {
        isValidAccountNumber = true;
    }

    return isValidAccountNumber;
};

export function validateNumber(event) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
    } else {
        return true;
    }
};

export function isValidateNumber(number) {
    var numberRegex = /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/;
    return numberRegex.test(number);
};



export function getDateByTime(time) {
    let date = new Date();
    if (time) {
        let timeArray = time.split(":");
        date.setHours(timeArray[0]);
        date.setMinutes(timeArray[1]);
    }
    return date;
};

export function appendNumberSuffix(num) {
    let suffix = "th";
    if (num === 0) suffix = "";
    if (num % 10 === 1 && num % 100 !== 11) suffix = "st";
    if (num % 10 === 2 && num % 100 !== 12) suffix = "nd";
    if (num % 10 === 3 && num % 100 !== 13) suffix = "rd";

    return num + suffix;
};

export function isUserAuthenticated() {
    let isAuthUser = (getAccessToken().length > 0);
    return isAuthUser;
};

export function getAccessToken() {
    let accessToken = "";
    let token = localStorage.getItem("accessToken");
    if (token !== null && token !== undefined) {
        accessToken = token;
    }
    return accessToken;
};

export function getUserId() {
    return localStorage.getItem("userId");
};

export function getCaptalLetterSentence(sentence) {
    let captalLetterSentence = "";
    if (sentence) {
        sentence = sentence.toLowerCase();
        captalLetterSentence = sentence.charAt(0).toUpperCase() + sentence.slice(1);
        return captalLetterSentence;
    }
    return sentence;

};

export function isAbsoluteUrl(url) {
    let regex = new RegExp('^(?:[a-z+]+:)?//', 'i');
    return regex.test(url);
}

export function clearCartLocalStorage() {
    localStorage.removeItem("cartId");
    localStorage.removeItem("itemsStoreId");
    localStorage.removeItem("orderUserName");
    localStorage.removeItem("orderEmail");
    localStorage.removeItem("orderPhone");
}

export function getFirstLetterCaptalSentence(sentence) {
    let captalLetterSentence = "";
    if (sentence) {
        captalLetterSentence = sentence.charAt(0).toUpperCase() + sentence.slice(1);
        return captalLetterSentence;
    }
    return sentence;

};


export function isDisabledProduct(disabled_until) {
    let isDisabledProduct = false;
    if (disabled_until !== null && disabled_until !== undefined) {
        let utcDate = new Date(disabled_until);
        let disabledDate = new Date(Date.UTC(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate(), utcDate.getHours(), utcDate.getMinutes(), utcDate.getMilliseconds()))
        isDisabledProduct = (disabledDate > new Date());
    }
    return isDisabledProduct;
}

export function validateNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
    } else {
        return true;
    }
};

export function getStoreName() {
    let storeName = "";
    let storeJson = localStorage.getItem("selectedStore");
    if (storeJson && storeJson !== undefined && storeJson !== null && storeJson !== "null") {
        let store = JSON.parse(storeJson);
        storeName = store.trade_name.toLowerCase().replace(/\s+/g, '-');
    }
    return storeName;
}

export function getStoreId() {
    let storeId = "";
    let storeJson = localStorage.getItem("selectedStore");
    if (storeJson && storeJson !== undefined && storeJson !== null && storeJson !== "null") {
        let store = JSON.parse(storeJson);
        storeId = store.id;
    }
    return storeId;
}

export function clearLocalStorage() {
    for (var key in localStorage) {
        if (key === "selectedStore" || key.startsWith(CommonConstants.SITE_TEXT) || key.startsWith(CommonConstants.STORE_TEXT)) {
            continue;
        }
        localStorage.removeItem(key);
    }
}

export function getStoreFormatedPhoneNumber(store) {
    return (store.phone_number_1 && store.phone_number_1 !== null ? store.phone_number_1.replace(/^\s*([0-9]{2})\s*\-?([0-9]{3})\s*\-?([0-9]{3,8})/, '$1 $2 $3') : "") + (store.phone_number_1 && store.phone_number_1 !== null && store.phone_number_2 && store.phone_number_2 !== null ? ", " : "") + (store.phone_number_2 && store.phone_number_2 !== null ? store.phone_number_2.replace(/^\s*([0-9]{2})\s*\-?([0-9]{3})\s*\-?([0-9]{3,8})/, '$1 $2 $3') : "");
}

export function getStoreFormatedMobileNumber(store) {
    return (store.mobile_number_1 && store.mobile_number_1 !== null ? store.mobile_number_1.replace(/^\s*([0-9]{2})\s*\-?([0-9]{3})\s*\-?([0-9]{3,8})/, '$1 $2 $3') : "") + (store.mobile_number_1 && store.mobile_number_1 !== null && store.mobile_number_2 && store.mobile_number_2 !== null ? ", " : "") + (store.mobile_number_2 && store.mobile_number_2 !== null ? store.mobile_number_2.replace(/^\s*([0-9]{2})\s*\-?([0-9]{3})\s*\-?([0-9]{3,8})/, '$1 $2 $3') : "");
}

export function getStoreAddress(store) {
    let storeAddress = "";
    storeAddress = (store.address_line_1 ? store.address_line_1 + ", " : "") + (store.address_line_2 ? store.address_line_2 + ", " : "") + (store.city && store.city.name ? store.city.name + ", " : "") + (store.zipcode ? store.zipcode : "");
    storeAddress = storeAddress.trim();
    storeAddress = storeAddress.length > 0 ? (storeAddress.slice(-1) === "," ? storeAddress.substring(0, storeAddress.length - 1) : storeAddress) : storeAddress;
    return storeAddress;
}


export function getUserDetails() {
    let user = {};
    let userId = localStorage.getItem("userId");
    if (userId && userId !== "null" && userId !== "undefined") user.id = userId;
    let userEmail = localStorage.getItem("userEmail");
    if (userEmail && userEmail !== "null" && userEmail !== "undefined") user.email = userEmail;
    let userPhone = localStorage.getItem("userPhone");
    if (userPhone && userPhone !== "null" && userPhone !== "undefined") user.phone = userPhone;

    let userLastName = localStorage.getItem("userLastName");
    if (userLastName && userLastName !== "null" && userLastName !== "undefined") user.lastName = userLastName;

    let userFirstName = localStorage.getItem("userFirstName");
    if (userFirstName && userFirstName !== "null" && userFirstName !== "undefined") user.firstName = userFirstName;
    return user;
};

export function getDeviceCompatableBanner(banner, isMobile) {
    let deviceCompatableBanner;
    if (isMobile) {
        deviceCompatableBanner = (banner.portrait_file ? banner.portrait_file : banner.landscape_file);
    } else {
        deviceCompatableBanner = (banner.landscape_file ? banner.landscape_file : banner.portrait_file);
    }
    return deviceCompatableBanner;
}

export function getOrderStatusData(deliveryStatusType) {
    const orderStatus = [
        {
            label: "Order placed",
            id: 2,
        },
        {
            label: "Order confirmed",
            id: 3,
        },
        {
            label: "Order being prepared",
            id: 5,
        },
        {
            label: "Ready to pickup",
            id: 6,
        },

    ];

    if (deliveryStatusType && deliveryStatusType === 2) {
        orderStatus.push(
            {
                label: "Your order is on the way",
                id: 7,
            }
        );
    }

    orderStatus.push(
        {
            label: "Delivered",
            id: 10,
        }
    );


    return orderStatus;
};

