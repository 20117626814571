import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';
import useResponsive from '../../hooks/useResponsive';



export default function RefundPolicyModal({ open, setOpenRundPolicyModal }) {
  const isMobile = useResponsive('only', 'xs');
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? "100%" : 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: isMobile ? 1 : 4,
  };
  const handleClose = (event, reason) => {

    if (reason && reason == "backdropClick")
      return;
    setOpenRundPolicyModal(false);
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="back-order-modal-title"
        aria-describedby="back-order-modal-description"
      >
        <Box sx={style}>

          <Typography sx={{ textAlign: "center" }} variant="h5" component="h2">
            Refund Policy
            <br />
            <br />
          </Typography>

          <Typography id={"back-order-modal-title"} variant="body1" component="div">
            Please select your order carefully as refunds are provided at the sole discretion of the store and are not guaranteed.
          </Typography>

          <Stack direction="row" justifyContent="flex-end" spacing={1.5} sx={{ mt: 2 }}>

            <Button color="inherit" variant="outlined" onClick={handleClose}>
              OK
            </Button>

          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
