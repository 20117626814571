import { Avatar, Box, Divider, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButtonAnimate } from '../../components/animate';
import Iconify from '../../components/Iconify';
// components
import MenuPopover from '../../components/MenuPopover';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { getUserDetails } from '../../utils/util';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Order History',
    linkTo: '/order-history',
    icon: "fontisto:history"
  },
  {
    label: 'Favorite Stores',
    linkTo: '/favourite-stores',
    icon: "ant-design:heart-filled"
  },
  {
    label: 'Saved Orders',
    linkTo: '/saved-orders',
    icon: "icon-park-solid:tag"
  },
  {
    label: 'Settings',
    linkTo: '/account-settings',
    icon: "eva:settings-fill"
  },
];

// ----------------------------------------------------------------------
AccountPopover.propTypes = {
  handleLogout: PropTypes.func,
  isMobile: PropTypes.bool
};
export default function AccountPopover({ handleLogout, isMobile }) {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  // const [user, setUser] = useState("");
  // const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  let userName = "";
  let user = getUserDetails();
  if (user.firstName) {
    userName = (user.firstName + (user.lastName ? " " + user.lastName : ""));
  }

  const isMountedRef = useIsMountedRef();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);

    if (user.email) {
      setUserEmail(user.email)
    }
  };

  const handleClose = (option) => {
    if (option.linkTo) {
      navigate(option.linkTo);
    }
    setOpen(null);

  };

  // const handleLogout = () => {
  //   navigate(pathConfigs.PATH_AUTH.login, { replace: true });

  //   if (isMountedRef.current) {
  //     handleClose();
  //   }
  // };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          ml: "32px !important",
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              // bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src="" alt={userName} />
        {!isMobile &&
          <Typography sx={{ pl: 1 }} variant="subtitle2" className="white-text-color" noWrap>
            {userName}
            <IconButton size="small" className={Boolean(open) ? "orange-text-color" : "unset"}>
              <Iconify icon="ph:caret-down-light" />
            </IconButton>

          </Typography>
        }
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        {isMobile &&
          <>
            <Box sx={{ my: 1, px: 2.5 }}>
              <Typography variant="subtitle2" className="black-text-color text-align-center" noWrap>
                {userName}
              </Typography>
              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userEmail}
          </Typography> */}
            </Box>

            <Divider />
          </>
        }

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} onClick={() => handleClose(option)}>

              <IconButton size="small" color="inherit">
                <Iconify icon={option.icon} />
              </IconButton>
              {option.label}
            </MenuItem>
          ))}

          <MenuItem onClick={() => handleLogout()}>
            <IconButton size="small" color="inherit">
              <Iconify icon="uis:signout" />
            </IconButton>
            Logout
          </MenuItem>
        </Stack>


      </MenuPopover>
    </>
  );
}
