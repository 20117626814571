import { lazy, Suspense } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
// components
import LoadingScreen from "../components/LoadingScreen";
import MainLayout from "../layouts/header/MainLayout";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      // path: "test",
      element: <MainLayout />,
      children: [
        { path: "/", element: <LandingPage /> },
        { path: "/search-store", element: <SearchStore /> },
        { path: "/test", element: <Test /> },
        { path: "/login", element: <Login /> },
        { path: "/reset-password", element: <ResetPassword /> },
        { path: "/register", element: <RegisterUser /> },
        { path: "/checkout", element: <Checkout /> },
        { path: "/logout", element: <Logout /> },
        { path: "/payment-confirmation", element: <PaymentConfirmation /> },
        { path: "/track-order", element: <TrackOder /> },
        { path: "/order-history", element: <OrderHistory /> },
        { path: "/saved-orders", element: <SavedOrders /> },
        { path: "/favourite-stores", element: <FavouriteStores /> },
        { path: "/near-stores", element: <NearStores /> },
        { path: "/account-settings", element: <AccountSettings /> },
        { path: "/store/:storeName", element: <StoreDetails /> },
        { path: "/deal/:dealId/customise", element: <DealDetails /> },
        { path: "/:storeName/contact", element: <Contact /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/careers", element: <Careers /> },
        { path: "/terms-conditions", element: <TermsAndConditions /> }



      ]
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound prevPath={window.location} pathname={window.location.pathname} pathHref={window.location.href} /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// Users
const Login = Loadable(lazy(() => import("../portal/pages/Login")));
const ResetPassword = Loadable(lazy(() => import("../portal/pages/ResetPassword")));
const RegisterUser = Loadable(lazy(() => import("../portal/pages/RegisterUser")));
const Logout = Loadable(lazy(() => import("../portal/pages/Logout")));
const SavedOrders = Loadable(lazy(() => import("../portal/pages/SavedOrders")));
const OrderHistory = Loadable(lazy(() => import("../portal/pages/OrderHistory")));
const FavouriteStores = Loadable(lazy(() => import("../portal/pages/FavouriteStores")));
const NearStores = Loadable(lazy(() => import("../portal/pages/NearStores")));

// Store
const StoreDetails = Loadable(lazy(() => import("../portal/pages/StoreDetails")));
const DealDetails = Loadable(lazy(() => import("../portal/pages/DealDetails")));
const Contact = Loadable(lazy(() => import("../portal/pages/Contact")));




const Test = Loadable(lazy(() => import("../portal/pages/Test")));
const SearchStore = Loadable(lazy(() => import("../portal/pages/StoreSearch")));
const LandingPage = Loadable(lazy(() => import("../portal/pages/LandingPage")));

//Payments
const PaymentConfirmation = Loadable(lazy(() => import("../portal/pages/PaymentConfirmation")));

// Track Order
const TrackOder = Loadable(lazy(() => import("../portal/pages/TrackOder")));


// Checkout
const Checkout = Loadable(lazy(() => import("../portal/pages/Checkout")));

// Account
const AccountSettings = Loadable(lazy(() => import("../portal/pages/AccountSettings")));



// Other
const NotFound = Loadable(lazy(() => import("../portal/pages/Page404")));
const AboutUs = Loadable(lazy(() => import("../portal/pages/AboutUs")));
const Careers = Loadable(lazy(() => import("../portal/pages/Careers")));
const TermsAndConditions = Loadable(lazy(() => import("../portal/pages/TermsAndConditions")));
