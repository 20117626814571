const CommonConstants = {

    // Customise(product and deal) modal button styles
    mdMinWidth: "240px !important",
    smMinWidth: "115px !important",
    mdHeight: "85px",
    smHeight: "40px",

    // Form Names
    SIZE: "size",
    BASE: "base",

    // Site-Announcement time interval
    SITE_ANNOUNCEMENT_TIME_INTERVAL: 10000, //10 SECONDS
    // Site-Announcement time interval
    STORE_ANNOUNCEMENT_TIME_INTERVAL: 10000, //10 SECONDS
    // Pizza max brand store
    PIZZA_CLUB_MAX_STORE_TEXT: "Pizza Club Max",
    // Auth
    AUTH_REQUIRED_TEXT: "required",
    AUTH_OPTIONAL_TEXT: "optional",
    NO_SUB_CATEGORY_TEXT: "No Sub category",
    DEALS_TEXT: "Deals",
    BEST_SELLER: "Best Seller",
    FAVOURITE: "Favourite",
    LEFT_HALF: "Left Half",
    RIGHT_HALF: "Right Half",
    SOURCE_TYPE_CART: "CART",
    FULL_PRODUCT_TEXT: "FULL PRODUCT",
    HNH_PRODUCT_TEXT: "HNH PRODUCT",
    FULL_PRODUCT_TEXT: "FULL PRODUCT",
    REQUEST_LOCATION_PRODUCT_DETAILS: "PRODUCT DETAILS",
    REQUEST_LOCATION_ALL_PRODUCTS: "ALL PRODUCTS",
    REQUEST_LOCATION_HNH_PRODUCT_DETAILS: "HNH PRODUCT DETAILS",
    LOCATION_NOT_FOUND_ERROR_MESSAGE: "We are not able to get your current location.",
    SEARCH_BY_LOCATION_TYPE: "Search by Location",
    NOT_ACCEPTING_ONLINE_ORDERS_MESSAGE: "Currently we are not accepting orders.",
    DELIVERABLE_DISABLED_MESSAGE: "We are currently not accepting orders for delivery.",
    PICKUPABLE_DISABLED_MESSAGE: "We are currently accepting orders only for deliveries.",
    TOPPING_REACHED_MESSAGE: "You can only add 4 extra toppings.",
    HNH_SIZE_MESSAGE: "Select the product size to configure your half n half pizza",
    HNH_BASE_MESSAGE: "Select the product base to configure your half n half pizza",
    HNH_SIZE_BASE_MESSAGE: "Select the product base and size to configure your half n half pizza",
    STORE_TEXT: "STORE-",
    SITE_TEXT: "SITE-",
    ADDRESS_TEXT: "ADDRESS",
    ORDER_DATE_TEXT: "ORDER DATE",
    DELIVERY_TYPE: "DELIVERY TYPE",
    VIEW_PRODUCT_PATH: "/view-product",

    // Footer links
    ALLERGEN_LINK_TEXT: "Allergen",
    ABOUT_US_LINK_TEXT : "About us",
    CONTACT_US_LINK_TEXT: "Contact us",
    TC_PRIVACY_POLICY_LINK_TEXT : "T&C and privacy policy",
    DISCLAIMER_LINK_TEXT:"Disclaimer",
    REFUND_POLICY_LINK_TEXT : "Refund Policy",



}

export default CommonConstants;