import { AppBar, Box, Divider, IconButton, Link, Stack, Toolbar } from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { IconButtonAnimate } from '../../components/animate';
import Iconify from '../../components/Iconify';
// components
import Logo from '../../components/Logo';
// config
import { HEADER } from '../../config';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import cssStyles from '../../utils/cssStyles';
import { clearLocalStorage, getStoreName, isUserAuthenticated } from '../../utils/util';
import AccountPopover from './AccountPopover';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { pathConfigs } from '../../routes/paths';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    // height: HEADER.MOBILE_HEIGHT,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

MainHeader.propTypes = {
    onOpenSidebar: PropTypes.func,
    isCollapse: PropTypes.bool,
    verticalLayout: PropTypes.bool,
};

export default function MainHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
    const navigate = useNavigate();
    const location = useLocation();
    let isAuthUserLoggedIn = false;
    if (location && location.state) {
        isAuthUserLoggedIn = location.state.isAuthUserLoggedIn;
    }
    const [isAuthUser, setAuthUser] = useState(false);

    const pageDetails = [
        { path: "/", name: "Home" },
        { path: "/store/", name: "Stores" },
        { path: "/search-store", name: "Stores Near me" },
        { path: "/track-order", name: "Order" },
        { path: "/payment-confirmation", name: "Track Order" },
        { path: "/order-history", name: "Order History" },
        { path: "/favourite-stores", name: "Favourite Stores" },
        { path: "/saved-orders", name: "Saved Orders" },
        { path: "/account-settings", name: "Settings" },
        { path: "/logout", name: "Sign Out" },
        { path: "/login", name: "Sign In" },
        { path: "/reset-password", name: "Forgot Password" },
        { path: "/register", name: "Register" },
        { path: "/checkout", name: "Payment" },
        { path: "/near-stores", name: "Near Stores" },
        { path: "/contact", name: "Contact" }

    ]


    const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

    const isMobile = useResponsive('only', 'xs');

    useEffect(() => {
        setAuthUser(isAuthUserLoggedIn ? isAuthUserLoggedIn : isUserAuthenticated());
    }, [isAuthUserLoggedIn])

    const getActiveTab = () => {
        let activeTab = "";
        let path = window.location.pathname;
        if (path === "/") activeTab = "Home";
        if (path === "/near-stores") activeTab = "Stores Near me";
        if (path.includes("/contact")) activeTab = "Contact";

        return activeTab;
    }

    const handleLogout = () => {
        clearLocalStorage();
        setAuthUser(false);
        navigate("/");

    };

    const getActivePage = () => {
        let activePage = "";
        let path = window.location.pathname;
        pageDetails.map(page => {
            if (path === page.path || (path === "/" && path.includes(page.path))) {
                activePage = page.name;
                return;
            }
        })
        // if (path === "/") activeTab = "Home";
        // if (path === "/near-stores") activeTab = "Stores Near me";

        return activePage;
    }


    return (
        <RootStyle className='header header-banner' isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}
            sx={{
                backgroundColor: '#000', backgroundImage: {
                    sm: "url(/images/header-bg-img.png)"
                }
            }}>

            <Toolbar
                sx={{
                    minHeight: '100% !important',
                    px: { lg: 5 },
                    height: isMobile ? 56 : 80,
                    ml: isMobile ? 1 : "139px",
                    mr: isMobile ? 1 : "139px"
                }}
            >
                {isMobile && (
                    <>
                        {getActivePage() !== "Home" &&
                            <Box sx={{ flexGrow: 1 }} >
                                <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: '#fff' }}>

                                    <img className="store-card-icon orange-text-color" src="/images/hamburger-menu.svg" />
                                </IconButtonAnimate>
                            </Box>
                        }

                        {getActivePage() === "Home" &&
                            <Box sx={{ flexGrow: 1 }} >
                                <Logo sx={{ width: 88, height: 39 }} />
                            </Box>
                        }



                        <Box sx={{ flexGrow: 1 }} className="white-text-color page-name" >
                            {getActivePage()}
                        </Box>

                        {isAuthUser ?
                            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }} sx={{ fontSize: 12 }}>
                                <AccountPopover handleLogout={handleLogout} isMobile={isMobile} />
                            </Stack>

                            :
                            <Link href="/login" variant="body2" className="header-option orange-text-color" to="/login" sx={{ color: "#F6B327 !important", fontSize: "14px !important" }}>
                                <IconButton size="small" color="inherit" sx={{ p: 0 }}>
                                    <Iconify icon="dashicons:lock" />
                                </IconButton>
                                Sign in / Sign up
                            </Link>
                        }
                    </>
                )}

                {!isMobile &&
                    <>
                        <Logo sx={{ width: 172, height: 76 }} />
                        <Box sx={{ flexGrow: 1 }} />

                        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }} sx={{ pt: "10px !important" }}>

                            <Link href="/" variant="body2" className={"header-option " + (getActiveTab() === "Home" ? 'active-tab' : "")} to="/">
                                Home
                            </Link>

                            {window.location.pathname.includes("/store/") &&
                                <>
                                    <Link component="span" variant="body2" className="header-pipe" sx={{ color: "#fff", fontSize: "inherit" }}>
                                        |
                                    </Link>
                                    <Link href={"/"+getStoreName()+ "/contact"} variant="body2" className={"header-option " + (getActiveTab() === "Contact" ? 'active-tab' : "")} to="/" sx={{ color: "#fff", fontSize: "inherit" }}>
                                        Contact
                                    </Link>
                                </>
                            }
                            <Link component="span" variant="body2" className="header-pipe" sx={{ color: "#fff", fontSize: "inherit" }}>
                                |
                            </Link>
                            <Link href="/near-stores" variant="body2" className={"header-option " + (getActiveTab() === "Stores Near me" ? 'active-tab' : "")} to="/" sx={{ color: "#fff", fontSize: "inherit" }}>
                                Stores Near me
                            </Link>
                            {/* <Link component="span" variant="body2" className="header-pipe" sx={{ color: "#fff", fontSize: "inherit" }}>
                                |
                            </Link>
                            <Link href="/careers" variant="body2" className="header-option" to="/" sx={{ color: "#fff", fontSize: "inherit" }}>
                                Careers
                            </Link> */}
                            {isAuthUser ?
                                <AccountPopover handleLogout={handleLogout} />
                                :
                                <Link href="/login" variant="body2" className="header-option orange-text-color" to="/login" sx={{ color: "#F6B327 !important", ml: "32px !important" }}>
                                    <IconButton size="small" color="inherit" sx={{ p: 0 }}>
                                        <Iconify icon="dashicons:lock" />
                                    </IconButton>
                                    Sign in / Sign up
                                </Link>
                            }

                        </Stack>
                    </>

                }


            </Toolbar>
            {isMobile &&
                <Divider className="thin-divider" />
            }
        </RootStyle>
    );
}
