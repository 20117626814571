import { Container, Divider, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import EmptyDivTag from '../../components/hook-form/EmptyDivTag';
import Iconify from '../../components/Iconify';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';
import { APP_CONFIGS } from '../../config';
import useResponsive from '../../hooks/useResponsive';
import RefundPolicyModal from '../../portal/common/RefundPolicyModal';
import CommonConstants from '../../portal/constants/CommonConstants';
import { getStoreId, getStoreName } from '../../utils/util';

// ----------------------------------------------------------------------



const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));



// ----------------------------------------------------------------------

export default function MainFooter() {



  const isMobile = useResponsive('only', 'xs');
  const [openRundPolicyModal, setOpenRundPolicyModal] = useState(false);

  if ((window.location.pathname.includes("/store/"))) {
    setTimeout(() => {
      let tncUrl = getTnCUrl();
      if (tncUrl) {
        let tncEle = document.getElementById("tncPrivacyPolicy");
        if (tncEle) {
          tncEle.style.display = "block";
        }

      }

    }, 4000);
  }


  const getTnCUrl = () => {
    let store = null;
    let storeJson = localStorage.getItem("selectedStore");
    if (storeJson && storeJson !== undefined && storeJson !== null && storeJson !== "null") {
      store = JSON.parse(storeJson);
      let url = store.terms_and_conditions_doc && store.terms_and_conditions_doc.url ? store.terms_and_conditions_doc.url : null;
      return url;
    }
  }

  const handleFooterLink = (event, name) => {
    event.preventDefault();
    if (name === CommonConstants.DISCLAIMER_LINK_TEXT) {
      window.open("https://pizzaclub.s3.ap-southeast-2.amazonaws.com/disclaimer.pdf", '_blank');
    } else if (name === CommonConstants.ALLERGEN_LINK_TEXT) {
      window.open(`${APP_CONFIGS.API_HOST}stores/${getStoreId()}/product-allergens`, '_blank');
    } else if (name === CommonConstants.TC_PRIVACY_POLICY_LINK_TEXT) {
      let url = getTnCUrl();
      if (!url) {
        return null;
      }
      window.open(url, '_blank');
    } else {
      setOpenRundPolicyModal(true);
    }

  }

  const getFooterLinks = () => {
    let LINKS = null;
    if (isMobile) {
      LINKS = [
        {
          headline: 'Quick Links',
          children: [
            { name: CommonConstants.ABOUT_US_LINK_TEXT, href: "/about-us" },
            { name: CommonConstants.ALLERGEN_LINK_TEXT, href: "" },
            { name: CommonConstants.CONTACT_US_LINK_TEXT, href: "" },
            { name: CommonConstants.TC_PRIVACY_POLICY_LINK_TEXT, href: "" },
            { name: CommonConstants.DISCLAIMER_LINK_TEXT, href: "" },
            { name: CommonConstants.REFUND_POLICY_LINK_TEXT, href: "" },
          ],
        }
      ];

    } else {
      LINKS = [
        {
          headline: 'Quick Links',
          children: [
            { name: CommonConstants.TC_PRIVACY_POLICY_LINK_TEXT, href: "" },
            { name: CommonConstants.DISCLAIMER_LINK_TEXT, href: "" },
            { name: CommonConstants.REFUND_POLICY_LINK_TEXT, href: "" }
          ],
        },
        {
          headline: '',
          children: [
            { name: CommonConstants.ABOUT_US_LINK_TEXT, href: "/about-us" },
            { name: CommonConstants.ALLERGEN_LINK_TEXT, href: "" },
            { name: CommonConstants.CONTACT_US_LINK_TEXT, href: "" }

          ],
        }
      ];

    }
    return LINKS;
  }


  const getFooterLink = (link) => {
    let linkHref = link.href;
    let isStorePage = (window.location.pathname.includes("/store/"));
    if (link.name === CommonConstants.ALLERGEN_LINK_TEXT || link.name === CommonConstants.CONTACT_US_LINK_TEXT) {
      if (!isStorePage) return null;
      linkHref = (link.name === CommonConstants.ALLERGEN_LINK_TEXT ? "" : ("/" + getStoreName() + "/contact"));
    } else if (link.name === CommonConstants.TC_PRIVACY_POLICY_LINK_TEXT) {
      if (!isStorePage) return null;
      let url = getTnCUrl();
      if (!url) {
        return null;
      }
    } else {
      linkHref = link.href;
    }

    return (<Link
      to={link.name === CommonConstants.REFUND_POLICY_LINK_TEXT ? "" : linkHref}
      target={(link.name === CommonConstants.DISCLAIMER_LINK_TEXT) ? "_blank" : "_self"}
      key={link.name}
      id={link.name === CommonConstants.TC_PRIVACY_POLICY_LINK_TEXT ? 'tncPrivacyPolicy' : ''}
      color="inherit"
      variant="body2"
      onClick={(link.name === CommonConstants.ALLERGEN_LINK_TEXT || link.name === CommonConstants.REFUND_POLICY_LINK_TEXT || link.name === CommonConstants.DISCLAIMER_LINK_TEXT || link.name === CommonConstants.TC_PRIVACY_POLICY_LINK_TEXT) ? (event) => handleFooterLink(event, link.name) : null}
      component={RouterLink}
      className="footer-option quick-link"
      sx={{ display: link.name === CommonConstants.TC_PRIVACY_POLICY_LINK_TEXT ? 'none' : 'block', textAlign: "left", mt: "5px !important" }}
    >
      <IconButton size="small" color="inherit" className="orange-text-color">
        <Iconify icon="emojione-monotone:large-blue-diamond" width="10px" height="10px" />
      </IconButton>
      {link.name}
    </Link>)
  }

  return (
    <RootStyle className='footer' sx={{ backgroundColor: "rgba(18,22,25,1)", color: "#fff" }}>
      <Grid
        container
        justifyContent={{ xs: 'center', md: 'space-between' }}
        sx={{ textAlign: { xs: 'center', md: 'left' } }}
        className="container"
      >
        {!isMobile &&
          <>
            <Grid item md="0.5"></Grid>
            <Grid item xs={3.2} sx={{ pt: 3.2, mb: 3 }} key="footerDescription">
              <Logo sx={{ mb: 3.2, width: 172, height: 76, mx: { xs: 'auto', md: 'inherit' } }} />
              <Typography variant="body2" className="footer-description" sx={{ pr: { md: 5 } }}>
                We accept all major credit & debit cards.
              </Typography>
              <Typography variant="body2" className="footer-description" sx={{ pr: { md: 5 }, pt: 2 }}>
                Your payment details have the security offered by Paymark.
              </Typography>

              <Stack
                direction="row"
                justifyContent={{ xs: 'center', md: 'flex-start' }}
                sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
              >

              </Stack>
            </Grid>
          </>
        }


        <Grid item xs={12} md={4} sx={{ px: (isMobile ? 2 : "unset") }}>
          <Stack
            spacing={5}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            sx={{ pt: isMobile ? 2.2 : 5.5, alignItems: (isMobile ? "center" : "unset") }}
          >
            {getFooterLinks().map((list) => (
              <Stack key={list.headline} spacing={2} sx={{ mt: "0px !important" }}>
                <Typography component="p" variant="overline" className="footer-heading" sx={{ pb: isMobile ? 2 : 1.5, textTransform: "capitalize" }}>
                  {list.headline}
                </Typography>
                {list.children.map((link) => (
                  <>
                    {getFooterLink(link)}


                  </>
                ))}
              </Stack>
            ))}
          </Stack>
        </Grid>

        <Grid item xs={12} md={3}>
          <Stack
            spacing={5}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            <Stack spacing={2}>

              {!isMobile &&
                <>
                  <EmptyDivTag />
                  <EmptyDivTag />
                  <EmptyDivTag />
                  <EmptyDivTag />
                  <EmptyDivTag />
                  <EmptyDivTag />
                  <EmptyDivTag />
                  <EmptyDivTag />
                  <EmptyDivTag />
                  <EmptyDivTag />
                  <EmptyDivTag />
                  <EmptyDivTag />
                  <EmptyDivTag />
                </>}
              <Typography
                component="p"
                variant="body2"
                sx={{
                  mt: 2,
                  pb: { md: 2 },
                  fontSize: 13,
                  textAlign: { xs: 'center', md: 'right' },
                }}
                key="footerFollowUs"
              >
                Follow us  <SocialsButton sx={{ mx: 0.5 }} />
              </Typography>
            </Stack>
          </Stack>
        </Grid>

      </Grid>

      <Divider className="thin-divider" sx={{ ml: { xs: "10px" }, mr: { xs: "10px" } }} />

      <Container sx={{ pt: 1, ml: { md: "240px" } }}>
        <Grid>
          <Grid item xs={12} sx={{ mb: 12 }}>
            <Typography
              component="p"
              variant="body2"
              sx={{
                mt: (isMobile ? 1 : 2),
                pb: (isMobile ? 1 : 2),
                fontSize: (isMobile ? 10 : 14),
                textAlign: { xs: 'center', md: 'left' },
              }}
              key="pizzaClubRights"
            >
              ©{new Date().getFullYear()} Pizza Club. All Rights Reserved
            </Typography>
          </Grid>

        </Grid>
      </Container>
      {openRundPolicyModal &&
        <RefundPolicyModal
          open={openRundPolicyModal}
          setOpenRundPolicyModal={setOpenRundPolicyModal}
        />
      }

    </RootStyle >
  );
}
