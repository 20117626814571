// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  MOBILE_NAVBAR_MIN_WIDTH: 200,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'dark',
  themeDirection: 'ltr',
  themeColorPresets: 'orange',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const APP_CONFIGS = {
  API_HOST: process.env.REACT_APP_HOST_API_KEY,
  CATEGORIES_PATH: "/categories",
  SUB_CATEGORIES_PATH: "/sub-categories",
  SITE_ANNOUNCEMENTS_PATH: "/site-announcements",
  STORES_PATH: "/stores",
  AUTOCOMPLET_LOCATION_PATH: "/locations/autocomplete",
  SITE_BANNERS_PATH: "/site-banners",
  AUTH_LOGIN_PATH: "/auth/users/login",
  FORGOT_PASSWORD_PATH: "/auth/users/forgot-password",
  AUTH_REGISTER_PATH: "/auth/users/register",
  USERS_PATH: "/users",
  FAVOURITE_STORES_PATH: "/favourite-stores",
  FAVOURITE_PRODUCT_PATH: "/favourite-products",
  ANNOUNCEMENTS_PATH: "/announcements",
  BANNERS_PATH: "/banners",
  MENUS_PATH: "/menus",
  TIME_SLOTS_PATH: "/time-slots",
  CARTS_PATH: "/carts",
  CART_CURRENT_STATUS_PATH: "/current-status",
  CARTS_ITEMS_PATH: "/items",
  SALES_ORDRES_PATH: "/sales-orders",
  CARDS_PATH: "/cards",
  PAYMENTS_PATH: "/payments",
  ADDRESSES_PATH: "/addresses",
  MENU_VARIATIONS: "/menu-variations",
  STORE_DEALS_PATH: "/store-deals",
  APPLY_DEAL_PATH: "/apply-deal",
  VALIDATE_VOUCHER_PATH: "/validate-voucher",
  REMOVE_DEAL_PATH: "/remove-deal",
  APPLICABLE_DEAL: "/applicable-deal",
  CALCULATE_DELIVERY_CHARGE_PATH:"/calculate-charges",
  CONTACT_US_PATH:"/contact-us",
  CONFIGURATION_KEY_PATH: "/configurations",
  VALIDATE_CAPTCHA_PATH: "/validate-recaptcha",
  SITES_PATH: "/sites",
  AVAILABLE_DEALS_PATH: "/available-deals",
  TOPPINGS_PATH: "/toppings"
};
