
import axios from 'axios';
import NProgress from 'nprogress';
import CommonConstants from '../portal/constants/CommonConstants';
// config
import { APP_CONFIGS } from '../config';
import { clearLocalStorage } from './util';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: APP_CONFIGS.API_HOST,
  timeout: 1000 * 20 // Wait for 20 seconds
});

axiosInstance.interceptors.request.use(request => {
  if (request.headers) {
    if (request.headers.isAuthStatus) {
      let accessToken = "";
      if (request.headers.isAuthStatus === CommonConstants.AUTH_REQUIRED_TEXT) {
        accessToken = localStorage.getItem("accessToken");
        if (accessToken === null || accessToken === undefined || accessToken === "") {
          window.location.href = '/login';
          return;
        }
      } else if (request.headers.isAuthStatus === CommonConstants.AUTH_OPTIONAL_TEXT) {
        accessToken = localStorage.getItem("accessToken");
      }
      if (request.headers) {
        if (accessToken) {
          request.headers.Authorization = "Bearer " + accessToken;
        }

      }


      delete request.headers.isAuthStatus;
    }
    request.headers["x-source-id"] = "2";
  }
  if (request && request.method !== "get") {
    NProgress.start();
  }
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response && response.config && response.config.method !== "get") {
      NProgress.done();
    }
    return response
  },
  (error) => {
    if (error && error.response) {
      if (error.response.status && (error.response.status === 403 || error.response.status === 401)) {
      clearLocalStorage();
        window.location.href = '/login';
        return;
      }
      
      if (error.response.config && error.response.config.method !== "get") {
        NProgress.done();
      }
    }
    return Promise.reject((error.response) || 'Something went wrong')
  }
);

export default axiosInstance;
