import { getStoreName } from "../../../utils/util";

// ----------------------------------------------------------------------

// const getIcon = (icon) => <Iconify icon={icon} />;



// const ICONS = {
//   menu: getIcon("bxs:food-menu"),
//   toppings: getIcon("bi:align-top"),
//   price: getIcon("ic:baseline-price-change"),
//   products: getIcon("bi:card-list"),
//   tables: getIcon("carbon:table-split"),
//   supplier: <FactoryOutlinedIcon />,
//   store: getIcon("bi:shop"),
// };

const getItems = () => {
  let items = [{
    title: "Home",
    path: "/",
    icon: ""
  }];
  if (window.location.pathname.includes("/store/")) {
    items.push({
      title: "Contact",
      path: "/" + getStoreName() + "/contact",
      icon: ""
    });
  }
  items.push({
    title: "Store Near me",
    path: "/near-stores",
    icon: ""
  });
  // items.push({
  //   title: "Careers",
  //   path: "/careers",
  //   icon: ""
  // });
  return items;
}

const sidebarConfig = [
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: getItems()
  }
];




export default sidebarConfig;
